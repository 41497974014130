import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-center text-2xl mb-8">404</h1>
      <h2 className="text-center text-xl mb-8">Page not found.</h2>
      <h3 className="text-center text-lg mb-8">
        The page you are looking for does not exist.
      </h3>
    </div>
  );
};

export default NotFound;
